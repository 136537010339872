<template>
    <div class="content flex" style="display: flex; justify-content: center;">
        <div class="edit">
            <el-form v-model="edit"  label-width="70px" class="tb-form" size="mini">
                <el-form-item label="话题标题">
                    <el-input placeholder="请输入" v-model="edit.title"></el-input>
                </el-form-item>
                <el-form-item label="话题简介">
                    <el-input placeholder="请输入" v-model="edit.subtitle"></el-input>
                </el-form-item>
                <el-form-item label="起止时间" v-model="edit.date">
                    <el-date-picker
                    v-model="edit.date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
                <el-form-item label="添加头图">
                    <div class="upload">
                        <Upload id="banner" src="activity" @success="onUpload">
                            <div class="logo mb-12" v-if="edit.logo">
                                <img :src="edit.logo" alt="img" width="100%">
                                <span class="exchange" @click.stop="deleteImgUrl">删除</span>
                            </div>
                            <div class="upload-box mb-12 flex-center" v-else>
                                <i class="el-icon-plus"></i>
                                <span class="text">点击上传</span>
                            </div>
                        </Upload>
                        <span class="tips">建议图片尺寸1125x720像素，且大小 &lt;=200KB，支持jpg、png格式</span>
                    </div>
                </el-form-item>
                <el-form-item label="话题排序">
                    <el-input placeholder="请输入" v-model="edit.sort" type="number"></el-input>
                </el-form-item>
                <el-form-item label="展示状态">
                    <el-radio-group v-model="edit.status">
                        <el-radio :label="1">已生效</el-radio>
                        <el-radio :label="0">未生效</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div style="display: flex;justify-content: center;">
                    <el-button type="primary" @click="onConfirm">确定</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import Upload from '@/components/Upload'
    export default {
        data() {
            return {
                edit:{
                    title: '',
                    subtitle: '',
                    status:1,
                    logo:'',
                    date: [],
                    sort: 9999
                }
            }
        },
        mounted() {
            this.id = this.$route.query.id * 1 || null
            if (this.id) {
                this.getDetail(this.id)
            }
        },
        methods: {
            onConfirm() {
                if (!this.edit.title) {
                    this.$message.error('请输入标题')
                    return 
                }
                if (!this.edit.subtitle) {
                    this.$message.error('请输入描述')
                    return 
                }
                if (!this.edit.logo) {
                    this.$message.error('请上传图片')
                    return 
                }
                if (!this.edit.date) {
                    this.$message.error('请选择日期')
                    return 
                }
                if (!this.edit.sort) {
                    this.$message.error('请输入排序号')
                    return
                }
                this.edit.start_time = this.edit.date[0]
                this.edit.end_time = this.edit.date[1]
                const url = 'admin/feed/topic/edit'
                this.$https.post(url, this.edit).then(res => {
                    if (res) this.$router.push('/feedTopic/list')
                })
            },
            async getDetail(id) {
                let data = await this.$https.get('admin/feed/topic/detail?id=' + id)
                if (data) {
                    data.date = [data.start_time,data.end_time]
                    this.edit = data
                }
            },
            onCancel() {
                this.$router.push('/feedTopic/list')
            },
            onUpload(url) {
                this.edit.logo = url
            },
            deleteImgUrl() {
                this.$confirm('是否删除该图片?', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.edit.logo = ""
                }).catch(() => {
                })
            }
        },
        components:{Upload}
    }
</script>

<style scoped lang="scss">
    .edit {
        padding: 0 32px;
        box-sizing: border-box;
    
        .tb-form {
            // width: 534px;
    
            .tips {
                padding-top: 8px;
                color: rgba(0, 0, 0, 0.45);
            }
    
            .demo-text {
                color: #1890FF;
                cursor: pointer;
            }
    
            .upload {
                display: flex;
                flex-direction: column;
                line-height: 1;
    
                &-box {
                    width: 375px;
                    height: 240px;
                    flex-direction: column;
                    border-radius: 4px;
                    border: 1px dashed rgba(0, 0, 0, 0.15);
                    cursor: pointer;
    
                    .text {
                        padding-top: 8px;
                        color: rgba(0, 0, 0, 0.65);
                    }
                }
    
                .logo {
                    position: relative;
                    width: 375px;
                    height: 240px;
                    overflow: hidden;
                    cursor: pointer;
    
                    .exchange {
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 28px;
                        line-height: 28px;
                        bottom: 0;
                        left: 0;
                        font-size: 12px;
                        color: #fff;
                        background-color: rgba(0, 0, 0, 0.45);
                        text-align: center;
                    }
                }
            }
        }
    }
</style>
